import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";
import Hero from "../../components/hero/Hero";

const Blog3 = () => {
  const imageFolder = "blog3";
  return (
    <div className="project-template">
      <Hero
        title="How to develop an effective UX design for an online store"
        blog={"12 FEB 2024"}
        minHeight
      />
      <ImageGroup
        columns={1}
        images={["blog-1"]}
        imageFolder={imageFolder}
        onlyDesktop
        firstImage
      />

      <InfoSection
        title="Key elements of exceptional UX"
        paragraphs={[
          `UX design is one of the key elements that determine how pleasant and convenient a user's interaction with an online store will be. Poor UX can repel a person so strongly that they won't want to make a purchase, let alone explore the website or app. How can one design an online store where people will joyfully buy products and want to come back again and again? Our team has compiled a comprehensive list of essential components for excellent UX in e-commerce and gathered some tips to make your store more appealing to customers. Let's start with the elements that form the foundation for both mobile and desktop versions of online stores.`, `In order for customers to focus on the products you offer without spending time trying to figure out how your online store works, make its design as familiar as possible. Users should intuitively navigate the interface or, at the very least, quickly learn as needed. Therefore, try not to experiment too much with the placement of buttons and the structure of product pages. Effective user interface design involves having subtle but noticeable branding on all platforms and in promotional materials. This way, the design will be associated with your product by potential customers, helping to increase brand recognition and memorability. In mobile interface design, there are also several rules. The most important is the 'rule of the big thumb,' which states that all key design elements should be placed within the area on the screen that the user can reach with their thumb.`
        ]}
        blog
      />

      <ImageGroup columns={1} images={["blog-2"]} imageFolder={imageFolder} onlyDesktop />

      <InfoSection
        title="Crafting engaging and informative main pages"
        paragraphs={[
          `As the main page is likely the first thing your customers will see, it needs to be interesting and informative. It should have a clear structure so that users can comfortably begin their journey through the online store here. The central focus on the main page is the value proposition. Additionally, it typically includes a prominent search panel, several promotional advertisements, and a footer with links and useful information. Additionally, an effective solution would be to add certificates, customer reviews, or a few advertising posts from social networks to the bottom of the page. This can help gain the trust of less decisive potential customers and likely dispel any doubts about the integrity of the company.`, `As the main page is likely the first thing your customers will see, it needs to be interesting and informative. It should have a clear structure so that users can comfortably begin their journey through the online store here. The central focus on the main page is the value proposition. Additionally, it typically includes a prominent search panel, several promotional advertisements, and a footer with links and useful information. Additionally, an effective solution would be to add certificates, customer reviews, or a few advertising posts from social networks to the bottom of the page. This can help gain the trust of less decisive potential customers and likely dispel any doubts about the integrity of the company.`,
        ]}
        blog
      />

      <ImageGroup
        columns={1}
        images={["blog-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Optimizing search panels"
        paragraphs={[
          `Whether customers can quickly and effortlessly find the necessary information on an e-commerce website or app depends on how user-friendly the search panel is. This includes both the search field itself and the results page. The more products your online store has, the more prominent this element should be. If necessary, move the search panel from the footer to the central part of the page. Avoid hiding the input field behind a magnifying glass icon; instead, allocate a sufficiently wide space for it next to the icon. The results page should match its characteristics: standard text elements should be arranged in list form, while images look better in the form of cards. Also, leave the search query text in the search field so that users can make changes without retyping the phrase.`,
        ]}
        blog
      />

    </div>
  );
};

export default Blog3;
