import React, { useEffect, useState } from "react";
import Hero from "../../components/hero/Hero";
import Button from "./../../components/shareable/Button";

const desktopTitle = "Full-service digital agency driving business growth";
const mobTitle = "Full-service digital agency for business growth";

const breakpoint = 450;

const HeroHome = () => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < breakpoint) {
        setTitle(mobTitle);
      } else {
        setTitle(desktopTitle);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Hero title={title}>
      <div className="buttons animate-element fade-up onload">
        <div className="cta-buttons">
          <Button type="light" text="Our portfolio" href="projects" />
          <Button type="dark" text="Contact us" href="contact" />
        </div>
        <Button type="scroll" href="#show-reel" />
      </div>
    </Hero>
  );
};

export default HeroHome;
