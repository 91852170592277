import React from "react";
import Button from "../../components/shareable/Button";

import Clutch from "../../assets/icons/clutch.svg";
import Stars from "../../assets/icons/stars.svg";

const About = () => {
  return (
    <section className="about-us container-narrow section-space animate-element fade-up">
      <h2>Design, build and maintain products</h2>
      <div className="description">
        <p>
        We are an international team of multidisciplinary designers, developers, and digital marketing experts collaborating with brands of various sizes. What sets us apart from everyone else is our focus on creating enjoyable, intuitive, engaging, and remarkable experiences for people — that’s our specialty.
        </p>
        <p>
        Projects wich we create are a blend of
innovative thinking and practical
solutions, ensuring they are both
unique and effective.
        </p>
      </div>
      <div className="clutch-box">
        <a
          href="https://clutch.co/profile/handmade#summary"
          target="_blank"
          rel="noreferrer"
          className="clutch-wrapper"
        >
          <img src={Clutch} className="clutch" alt="" />
        </a>
        <div>
          <img src={Stars} className="stars" alt="" />
          <p className="small-text">
            Rated 5.0/5.0 for design and <br />
            development services
          </p>
        </div>
      </div>
      <Button type="dark" text="About us" href="about" />
    </section>
  );
};

export default About;
