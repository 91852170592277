import React from "react";
import ImageSection from "../../components/shareable/ImageSection";
import Hero from "./../../components/hero/Hero";

import AboutImage from "../../assets/images/about.jpg";
import DescriptionSection from "../../components/shareable/DescriptionSection";
import RenderAward from "./RenderAward";

import "./about.css";
import Counter from "./Counter";
import Logos from "./Logos";
import Faq from "./Faq";

const icons = ["award1", "award2"];

const texts = [
  [
    `From the moment our company was founded, we have helped our clients
        find exceptional solutions for their businesses, creating memorable
        brands, digital products, and effective digital marketing strategies. Our expertise grows with each year, and
        our accumulated experience empowers us to develop products exactly as
        they should be.`,
  ],
  [
    `We make things, and we're awesome at it. Handmade is a tight-knit team
        of experts who are ready to tackle the most intricate puzzles when it
        comes to digital products and marketing. We love what we do and
        we bet on the success of each and every project we undertake.`,
  ],
];

const About = () => {
  const description = (
    <>
      <span>10+</span>{" "}
      <span>
        awards and <br /> nomenees
      </span>
    </>
  );
  return (
    <>
      <Hero title="Creativity meets technology" />
      <ImageSection image={AboutImage} />
      <DescriptionSection
        icons={icons}
        texts={texts}
        title="Our goal"
        description={description}
      />
      <RenderAward />
      <Counter />
      <Logos />
      <Faq />
    </>
  );
};

export default About;
