import React from "react";
import FaqItem from "./FaqItem";

const lists = [
  {
    question: "What separates Handmade from other digital agencies?",
    answer:
      "We get this question a lot. There are many competent digital agencies in Serbia alone and even more globally. It’s hard to be different when the standard is so high. So how do we stand out? First and foremost, it’s the quality of our work and the craft it takes to make it. The digital products and user interfaces we create are easy to use, look great, and represent your brand in the best way possible. Then, it’s the people who make your project a reality. We always assign a multidisciplinary team of best designers, developers, and marketing specialists.",
  },
  {
    question: "What is your key expertise?",
    answer:
      "We specialize in complete product development and marketing: from the very first ideation stages, through design, prototyping and testing to development, launch and marketing. Our development team can deliver both web and mobile products using most popular technologies.",
  },
  {
    question: "Do you do branding or should I hire a separate branding agency?",
    answer:
      "Yes, we do. We position ourselves as a new kind of design agency focused on companies from many different fields. Not so many agencies can create a visual identity for a concierge services like we did for Belgrades Finest. Our branding capabilities include research, brand and digital strategy, logo design, visual identity, graphic design, motion design, and style guide development, and web design, among other things.",
  },
  {
    question: "How much does it cost to hire you for a project?",
    answer:
      "Our pricing is directly related to the project scope, timeline, deliverables, and team composition. However, all our engagements are fixed‑price or monthly subscription contracts. In this case, we’ll give you a detailed proposal after learning as much as possible about your project. Please email us to get a quote.",
  },
  {
    question: "How can I get a proposal for my project?",
    answer:
      "If you're looking for a design, development agency or digital marketing agency, please send us an email first with a quick summary of your project. We usually reply within 24 hours to schedule an introductory call and ask any immediate questions. During our first call, we will ask a lot of questions about the project goals, audience, budget, timeline, and other essential details. We can sign an NDA if necessary, but we always treat all client conversations as confidential. While many agencies only work by the hour, we try to estimate the project cost as precisely as possible before we start. After the call, we may request various documentation such as a design brief, project requirements, past designs, etc.",
  },
  {
    question: "How am I billed for your digital marketing services and is there a contract?",
    answer:
      "Most of our services are three-month and six-month contracts that are offered at discounted rates for extended service periods. We prefer longer deals because digital marketing results do not happen overnight, especially if you are tackling efforts like social media marketing and search engine optimization. The further a digital marketing campaign gets from commencement the more successful the campaign will be. Why? Because we get the opportunity to optimize the campaign based on real market data and platforms optimize utilizing machine learning and AI capabilities to drive better marketing performance.",
  },
  {
    question: "What tools and technology will you use to build my website and/or app?",
    answer:
      "We use only the tools and technology that will be most effective for your business. We will determine the most suitable platform for your needs and then design and develop your product accordingly. We are proficient in platforms like Shopify for e-commerce, Webflow for websites, and custom HTML/CSS/JS/REACT for websites and apps.",
  },
  {
    question: "Do you provide any website/app warranty or guarantee?",
    answer:
      "Yes. We stand by our products and service 100% of the time. We have a 30-day bug testing period that normally allows us to hash out any bugs or issues that we may be experiencing. However, if something breaks on your website unexpectedly, we will be there to fix it and get you back up and running assuming the break was not caused by some other additional developer that was working on the site. But developing apps is more complex, as they require continuous support from our development team due to regular updates for mobile devices, including iOS and Android. And we have special packages for that.",
  },
  {
    question: "Do you send me monthly reports for digital marketing services or how will I know the efforts are working?",
    answer:
      "Yes, we also set you up on our state-of-the-art reporting dashboard that compiles your entire online presence and puts it into one central place for you. This dashboard is incredible and let’s you get a snapshot of your business presence online and instantly. The dashboard comes with 24/7/365, up-to-the-minute reporting.",
  },
  {
    question: "Do you provide any guarantees for your SEO work?",
    answer:
      "We are confident that with our services, your traffic will only increase and your visibility online will only continue to grow. We pride ourselves on being extremely transparent, which is why we provide you with tools like our online reporting dashboard that actually shows you how things are doing. No more working with agencies that hide or manipulate data in their reporting.",
  },
  {
    question: "How do you structure my PPC advertising campaign?",
    answer:
      "We structure your PPC advertising campaigns based around your products and services. We create extremely targeted ads for each individual service. We will never run generic ads because these do not convert. For example, if you offer insurance services, we will identify your best service options. If that option is car insurance, we will create specific ads and landing pages exactly targeted for people looking for car insurance services in your area. This ensures that the traffic we are driving to your website is traffic that is looking for what we are providing to them!",
  },
  {
    question: "Which social media accounts do you manage for my business?",
    answer:
      "We manage and create content for the following social media platforms: Facebook, Twitter(X), Tiktok, Instagram, LinkedIn, Pinterest, and YouTube.",
  },
];

const Faq = () => {
  return (
    <section className="faq section-space container-narrow">
      <h2 className="animate-element fade-up">Frequently asked questions</h2>

      <div className="faq-wrapper">
        {lists.map((item, index) => (
          <FaqItem key={index} item={item} />
        ))}
      </div>
    </section>
  );
};

export default Faq;
