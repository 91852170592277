import React from "react";
import ImageSection from "../../components/shareable/ImageSection";
import Hero from "./../../components/hero/Hero";

import Image from "../../assets/images/services.png";
import DescriptionSection from "../../components/shareable/DescriptionSection";

import "./services.css";
import WhiteTrophySection from "./WhiteTrophySection";
import RenderServices from "./RenderServices";

const icons = ["react", "javascript", "html", "css", "angular", "plus"];

const texts = [
  [
    `We create products, brands, apps, websites, and digital marketing strategies for companies all around the world. To us, world-class digital products take more than intelligent code or creative design. It requires true partnership between the ideators and the innovators. Our collaborations are built on a bedrock of transparency, communication, expert project management, and attention to detail.`,
  ],
  [
    `From the moment our company was founded, we have helped our clients find exceptional solutions for their businesses, creating memorable brands, digital products, and effective marketing strategies.`,
  ],
];

const Services = ({ projects }) => {
  const description = (
    <>
      <span></span>
      <span>
      Extensive digital services
      </span>
    </>
  );

  return (
    <div className="services-page">
      <Hero title="We’ve got skills to make a statement." />
      <ImageSection image={Image} />
      <DescriptionSection
        icons={icons}
        texts={texts}
        title="Your digital excellence"
        description={description}
      />
      <WhiteTrophySection />
      <RenderServices projects={projects} />
    </div>
  );
};

export default Services;
