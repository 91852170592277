import React, { useEffect, useState } from "react";

import Home from "./pages/Home/Home";
import Projects from "./pages/Projects/Projects";
import { animateElement, handleLoad } from "./scripts/textAnimation";
import Navigation from "./components/navigation/Navigation";
import Footer from "./components/footer/Footer";
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import Contact from "./pages/Contact/Contact";
import Blogs from "./pages/Blogs/Blogs";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";

import { Routes, Route, useLocation } from "react-router-dom";

//IMPORT PROJECTS
import Creddapp from "./pages/ProjectPost/Creddapp";
import Quran from "./pages/ProjectPost/Quran";
import Barnsley from "./pages/ProjectPost/Barnsley";
import Hybrid from "./pages/ProjectPost/Hybrid";
import Crypterium from "./pages/ProjectPost/Crypterium";
import Execwebsite from "./pages/ProjectPost/Execwebsite";
import Betero from "./pages/ProjectPost/Betero";
import Bgfinest from "./pages/ProjectPost/Bgfinest";
import Brivian from "./pages/ProjectPost/Brivian";
import Wavelle from "./pages/ProjectPost/Wavelle";
import Custathread from "./pages/ProjectPost/Custathread";
import Jetprojectlanding from "./pages/ProjectPost/Jetprojectlanding";
import Darkfrontierspage from "./pages/ProjectPost/Darkfrontierspage";
import Dylan from "./pages/ProjectPost/Dylan";
import Gotoafrica from "./pages/ProjectPost/Gotoafrica";
import Ellion from "./pages/ProjectPost/Ellion";
import Restore from "./pages/ProjectPost/Restore";
import Virtualclassroom from "./pages/ProjectPost/Virtualclassroom";
import Voltoge from "./pages/ProjectPost/Voltoge";
import Viziyaarchitecture from "./pages/ProjectPost/Viziyaarchitecture";
import Goatagency from "./pages/ProjectPost/Goatagency";
import Happn from "./pages/ProjectPost/Happn";
import Sliperymoneys from "./pages/ProjectPost/Sliperymoneys";
import Favtap from "./pages/ProjectPost/Favtap";
import Ecc from "./pages/ProjectPost/Ecc";
import Newrali from "./pages/ProjectPost/Newrali";
import Loanscape from "./pages/ProjectPost/Loanscape";
import Hyperbolic from "./pages/ProjectPost/Hyperbolic";
import Demoservices from "./pages/ProjectPost/Demoservices";
import Measured from "./pages/ProjectPost/Measured";
import Alecia from "./pages/ProjectPost/Alecia";

import Blog1 from "./pages/BlogPost/Blog1";
import Blog2 from "./pages/BlogPost/Blog2";
import Blog3 from "./pages/BlogPost/Blog3";

import VideoIntro from "./components/videoIntro/videoIntro";

const App = () => {
  const [projects, setProjects] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const location = useLocation();

  useEffect(() => {
    const initLoad = () => {
      fetch(process.env.PUBLIC_URL + "/json/projects.json")
        .then((res) => res.json())
        .then((data) => {
          setProjects([...data]);
        });

      fetch(process.env.PUBLIC_URL + "/json/blogs.json")
        .then((res) => res.json())
        .then((data) => {
          setBlogs([...data]);
        });
    };

    initLoad();
  }, []);

  useEffect(() => {
    const initLoad = () => {
      if (document.readyState === "complete") {
        handleLoad();
      } else {
        setTimeout(() => {
          initLoad();
        }, 50);
      }
    };

    initLoad();

    window.addEventListener("resize", handleLoad);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });

    return () => {
      window.removeEventListener("resize", handleLoad);
    };
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", animateElement);
    return () => window.removeEventListener("scroll", animateElement);
  }, []);

  // useEffect(() => {
  //   window.history.scrollRestoration = "manual";
  // }, []);

  return (
    <ScrollToTop>
      <Navigation />

      <VideoIntro />
      <Routes>
        <Route path="/" element={<Home projects={projects} />} />
        <Route path="/projects" element={<Projects projects={projects} />} />
        <Route path="/blogs" element={<Blogs blogs={blogs} />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services projects={projects} />} />
        <Route path="/contact" element={<Contact />} />

        <Route
          path="/website-redesign-what-benefits-can-it-bring-to-your-business"
          element={<Blog1 />}
        />
        <Route path="/design-successful-e-commerce-website" element={<Blog2 />} />
        <Route path="/how-to-develop-an-effective-ux-design-for-an-online-store" element={<Blog3 />} />

        <Route path="/creddapp" element={<Creddapp />} />
        <Route path="/barnsley" element={<Barnsley />} />
        <Route path="/hybrid" element={<Hybrid />} />
        <Route path="/crypterium" element={<Crypterium />} />
        <Route path="/execwebsite" element={<Execwebsite />} />
        <Route path="/betero" element={<Betero />} />
        <Route path="/bgfinest" element={<Bgfinest />} />
        <Route path="/brivian" element={<Brivian />} />
        <Route path="/wavelle" element={<Wavelle />} />
        <Route path="/custathread" element={<Custathread />} />
        <Route path="/jetprojectlanding" element={<Jetprojectlanding />} />
        <Route path="/quran" element={<Quran />} />
        <Route path="/darkfrontierspage" element={<Darkfrontierspage />} />
        <Route path="/dylan" element={<Dylan />} />
        <Route path="/gotoafrica" element={<Gotoafrica />} />
        <Route path="/ellion" element={<Ellion />} />
        <Route path="/restore" element={<Restore />} />
        <Route path="/virtualclassroom" element={<Virtualclassroom />} />
        <Route path="/voltoge" element={<Voltoge />} />
        <Route path="/viziyaarchitecture" element={<Viziyaarchitecture />} />
        <Route path="/goatagency" element={<Goatagency />} />
        <Route path="/happn" element={<Happn />} />
        <Route path="/sliperymoneys" element={<Sliperymoneys />} />
        <Route path="/favtap" element={<Favtap />} />
        <Route path="/ecc" element={<Ecc />} />
        <Route path="/loanscape" element={<Loanscape />} />
        <Route path="/newrali" element={<Newrali />} />
        <Route path="/hyperbolic" element={<Hyperbolic />} />
        <Route path="/demoservices" element={<Demoservices />} />
        <Route path="/measured" element={<Measured />} />
        <Route path="/alecia" element={<Alecia />} />
      </Routes>

      <Footer />
    </ScrollToTop>
  );
};

export default App;
