import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";
import Hero from "../../components/hero/Hero";

const Blog1 = () => {
  const imageFolder = "blog1";

  return (
    <div className="project-template">
      <Hero
        title="Website redesign - what benefits can it bring to your business?"
        blog={"12 JAN 2024"}
        minHeight
      />

      <ImageGroup
        columns={1}
        images={["blog-1"]}
        imageFolder={imageFolder}
        onlyDesktop
        firstImage
      />

      <InfoSection
        title="Unlocking business success in the digital age"
        paragraphs={[
          `In the era of digital technologies, primarily focused on mobile devices, a modern and user-friendly website is not just a necessity but a critically important component of your business's success. Think of your website as a showcase for your business. Every potential client will be comparing your offerings online with those of your competitors before deciding which one instills more trust. If done correctly, your website will function as your salesperson, working around the clock and without weekends. Before embarking on website changes, many wonder if it will really pay off and if the investment of funds and time is worth it.`,
        ]}
        blog
      />

      <ImageGroup
        columns={1}
        images={["blog-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Unveiling your brand's essence"
        paragraphs={[
          `The role of your website is to tell the story of your brand, highlight the problems you solve, and showcase what sets you apart from others. The challenge lies in having just about five seconds to capture the user's attention before they navigate away to something more interesting. Every year, significant changes occur in the field of website design, introducing new user scenarios and tools. The design of visual elements itself follows trends. To stay current, it is essential to regularly assess the relevance of your brand style and website.`,
        ]}
        blog
      />

      <ImageGroup
        columns={1}
        images={["blog-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Evolve with shifting audiences"
        paragraphs={[
          `It's quite possible that over time, your audience has changed, or the product or service itself has evolved. Each type of user your website targets has specific needs. Therefore, a website redesign will help align it with the expectations of your new potential customers.`,
          `Redesigning your website is an excellent opportunity to enhance your brand, change its visual components, and stay in tune with the times. Besides the obvious benefits at first glance, a new website can become your reliable assistant and a 'round-the-clock salesperson' for attracting new customers and retaining existing ones. When deciding on changes, it is essential to approach the selection of service providers with great responsibility, avoid rushing, and carefully study the portfolio of the chosen studio to ensure it aligns with your goals and expectations.`,
        ]}
        blog
      />
    </div>
  );
};

export default Blog1;
