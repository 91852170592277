import React from "react";

import Trophy from "../../assets/video/trophy.mp4";
import AwardItem from "./AwardItem";

const awardList = [
  {
    name: "Awwwards",
    category: "Honorable",
    count: 6,
  },
  {
    name: "Behance",
    category: "UI Featured",
    count: 1,
  },
  {
    name: "CSSDA",
    category: "Special kudos",
    count: 5,
  },
  {
    name: "CSSDA",
    category: "Innovation",
    count: 5,
  },
  {
    name: "CSSDA",
    category: "UI award",
    count: 5,
  },
  {
    name: "CSSDA",
    category: "UX award",
    count: 5,
  },
  {
    name: "Css Winner",
    category: "Star award",
    count: 2,
  },
];

const Award = ({ sectionRef }) => {
  return (
    <section
      ref={sectionRef}
      id="award"
      className="awards container-narrow section-space"
    >
      <div className="counter animate-element fade-up" data-parent="true">
        20+
      </div>

      <h2 className="animate-element fade-up" data-parent="true">
        Awards for <br />
        digital innovation
      </h2>

      <div className="trophy">
        <video autoPlay muted playsInline loop>
          <source src={Trophy} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="award-list">
        <ul>
          {awardList.map((item, index) => (
            <AwardItem item={item} key={index} />
          ))}
          <li className="more animate-element fade-up" data-parent="true">
            And many more...
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Award;
