import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";
import Hero from "../../components/hero/Hero";

const Blog2 = () => {
  const imageFolder = "blog2";
  return (
    <div className="project-template">
      <Hero
        title="Design successful e-commerce website"
        blog={"5 FEB 2024"}
        minHeight
      />
      <ImageGroup
        columns={1}
        images={["blog-1"]}
        imageFolder={imageFolder}
        onlyDesktop
        firstImage
      />

      <InfoSection
        title="Emphasizing product value and streamlining UX"
        paragraphs={[
          `The home page is the first impression. Buyers should be able to quickly establish the value of a product. Don't use the homepage to talk about specifications. Rather, help customers imagine how the product will solve their problems and improve their lives (a great starting point is the Jobs to Be Done framework). Write briefly and to the point. Use photographs that illustrate the benefits of the product. If customers have to read a lot, scroll through a page, or watch long explainer videos to understand what a product is good for, then its value is not obvious, which is bad.`, `TWhen the site only sells a couple of products, the search bar can be dispensed with, but as the range expands, it becomes an important tool for finding products in accordance with the mental models of users. Sticky search bars make it available on every page, but this feature should be removed from the checkout page to avoid messing up your conversion rate.`
        ]}
        blog
      />

      <ImageGroup columns={1} images={["blog-2"]} imageFolder={imageFolder} onlyDesktop />

      <InfoSection
        title="Maximizing clarity and impact in e-commerce design"
        paragraphs={[
          `Too original product names with a hidden meaning can confuse buyers. Take care of the context. Names with model numbers or descriptions of manufacturing techniques risk alienating non-specialists. The same can be said for names that are too cute or creative. Choose language that reflects the benefit or value from a customer's point of view.`, `Buyers come to e-commerce sites for specific products. When special offers and promotions are merged into the main page, they go unnoticed. Highlight stocks with vibrant colors, bold fonts, and full-screen photos. If the sale is secondary, it should be prominent but not compete with the homepage's main value proposition. If a promotion is the first thing buyers need to know about, focus on it.`, `Computer screens are ideal for displaying product grids. The grids follow the F-pattern scan and allow shoppers to quickly compare multiple options. They are especially effective for products that are easy to show in the photo. To get the most out of the grids, aim for two to four objects per row. When there are five or more of them, it becomes much more difficult to scan the grid. Bonus: Lists work too, but they work best for products with more detailed descriptions.`
        ]}
        blog
      />

      <ImageGroup
        columns={1}
        images={["blog-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Leveraging social proof for enhanced consumer confidence"
        paragraphs={[
          `Mark best-selling products with unique icons or place them in the appropriate section. Why? Bestsellers are a form of social proof. When people are in doubt and want to decide what to do next, they look at how others act. This is especially true for new buyers. The same principle applies to new products. Showcasing new and improved products isn't just a one-time sales tactic, it's a way to keep shoppers coming back for updates in the future.`, `Social proof builds consumer confidence. Ratings and reviews have a huge impact on the decision-making process, but desktop sites are spacious enough that we can use other forms of social proof: Photographs of goods taken by buyers Positive reviews from celebrities and influencers Expert comments Endorsements from independent review sites The goal of social proof is to help shoppers feel like they are making informed decisions that align with other people's positive experiences.`,
        ]}
        blog
      />

    </div>
  );
};

export default Blog2;
